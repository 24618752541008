import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Typography,
  Container,
  Card,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Divider,
  Button,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Logo from '../../../../assets/images/bulbul-ahmed-QFWGnLHnBNQ-unsplash.jpg';
import Image1 from '../../../../assets/images/alex-azabache-y2ErhoE92KA-unsplash.jpg';
import Image2 from '../../../../assets/images/atul-vinayak-jKvwtbrxzdY-unsplash.jpg';
import ColumnChart from './columnChart';
import AreaChart from './areaChart';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { toast } from 'react-toastify';
import Loader from '../../../../component/customComponents/loader/Loader';
import { ConvertIntoTwoDigits } from '../../../../component/customComponents/commonFunctions/CommonFunctions';

import AdminIndex from '../../AdminIndex';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

//#region ALL HOOKS AND VARIABALS
const StyledCard = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  justifyContent: 'left',
  padding: '25px',
  width: '100%',
  border: '1px solid',
  borderRadius: '0px',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const carousel = (
  <Container
    zeroMinWidth
    sx={{
      width: { xs: '330px', md: '350px' },
      height: { xs: 'auto', md: '200px' },
    }}
  >
    <OwlCarousel
      sx={{
        zIndex: 0,
        display: 'gird',
        justifyContent: 'center',
      }}
      className="owl-theme"
      margin={1}
      nav
      items="1"
      dots={false}
      autoplay
      loop
    >
      <img zIndex={0} height="200px" width="100%" src={Logo} />

      <img height="200px" width="100%" src={Image1} />

      <img height="200px" width="100%" src={Image2} />
    </OwlCarousel>
  </Container>
);

const { getDynamicCount } = AdminIndex.AdminSagaActions;

export default function DashBoard() {
  const dispatch = AdminIndex.useDispatch();
  let isToken = localStorage.getItem('auth');
  let token = localStorage.getItem('auth');
  // const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  console.log(productData.productData?.productCount,"productData");
  
  isToken =
    isToken == '' || isToken == null || isToken == undefined ? false : true;

  function createData1(date, buyer, seller, product, bidprice) {
    return { date, buyer, seller, product, bidprice };
  }

  const getProductData = async () => {
    dispatch(getDynamicCount(token));
  };

  useEffect(() => {
    getProductData();
  }, []);

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });
  
  const dynamicCount = AdminIndex.useSelector((state) => {
    return state.AdminSaga.dynamicCount;
  });

  useEffect(() => {
    setProductData(dynamicCount);
  }, [dynamicCount]);

  const rows1 = [
    createData1('24-04-2022', 'Name', 'Name', 'Name', '0000'),
    createData1('24-04-2022', 'Name', 'Name', 'Name', '0000'),
    createData1('24-04-2022', 'Name', 'Name', 'Name', '0000'),
    createData1('24-04-2022', 'Name', 'Name', 'Name', '0000'),
  ];

  let supportBox = (
    <Box sx={{ display: 'flex', m: 1, border: 1, p: 2 }}>
      <Typography alignSelf="center">Support request From User</Typography>
      <Box sx={{ flexGrow: { xs: 0.5, md: 1, lg: 1 } }} />
      <Button variant="contained" sx={{ textTransform: 'none' }}>
        View
      </Button>
    </Box>
  );

  let boxData = [
    supportBox,
    supportBox,
    supportBox,
    supportBox,
    supportBox,
    supportBox,
    supportBox,
    supportBox,
    supportBox,
    supportBox,
    supportBox,
  ];

  let itemList = boxData.map((item, index) => {
    return <Box key={index}>{item}</Box>;
  });

  const options = {
    items: 1,
    loop: true,
    margin: 0,
    nav: true,
    dots: false,
    stagePadding: 0,
  };
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Container
            sx={{
              mt: 2,
              //  borderBottom: 1,
              p: 3,
            }}
            maxWidth="false"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <StyledCard
                  sx={{ backgroundColor: '#f5f5f5', minHeight: '140px' }}
                >
                  <Grid container>
                    <Grid item lg={6}>
                      Total Products{' '}
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {
                          productData?.productData?.productCount
                          ? ConvertIntoTwoDigits(  productData?.productData?.productCount)
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Approved Products
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        { productData?.productData?.productApprove

                          ? ConvertIntoTwoDigits(
                            productData?.productData?.productApprove

                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Pending Products
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.productData?.productPending
                          ? ConvertIntoTwoDigits(
                            productData?.productData?.productPending
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Rejected Products
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.productData?.productReject
                          ? ConvertIntoTwoDigits(
                            productData?.productData?.productReject
                            )
                          : '00'}
                      </strong>
                    </Grid>
                  </Grid>
                </StyledCard>
              </Grid>

              <Grid item xs={12} md={4} lg={4} sm={6}>
                <StyledCard
                  sx={{ backgroundColor: '#f5f5f5', minHeight: '148px' }}
                >
                  <Grid container>
                    <Grid item lg={6}>
                      Total Orders{' '}
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.orderData?.orderCount
                          ? ConvertIntoTwoDigits(productData?.orderData?.orderCount)
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Close Orders
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.orderData?.closeOrder
                          ? ConvertIntoTwoDigits(
                            productData?.orderData?.closeOrder
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Open Orders
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.orderData?.openOrder
                          ? ConvertIntoTwoDigits(
                            productData?.orderData?.openOrder
                            )
                          : '00'}
                      </strong>
                    </Grid>
                  </Grid>
                </StyledCard>
              </Grid>

              <Grid item xs={12} md={4} lg={4} sm={6}>
                <StyledCard sx={{ backgroundColor: '#f5f5f5' }}>
                  <Grid container>
                    <Grid item lg={6}>
                      Total Users{' '}
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.userData?.userCount
                          ? ConvertIntoTwoDigits(productData?.userData?.userCount)
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Approved Users
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.userData?.approveUser
                          ? ConvertIntoTwoDigits(
                            productData?.userData?.approveUser
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Pending Users
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {   productData?.userData?.pendingUser
                          ? ConvertIntoTwoDigits(
                            productData?.userData?.pendingUser
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Rejected Users
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        { productData?.userData?.rejectUser
                          ? ConvertIntoTwoDigits(
                            productData?.userData?.rejectUser
                            )
                          : '00'}
                      </strong>
                    </Grid>
                  </Grid>
                </StyledCard>
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <StyledCard sx={{ backgroundColor: '#f5f5f5' }}>
                  <Grid container>
                    <Grid item lg={6}>
                      Total Returns{' '}
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.returnData?.returnCount
                          ? ConvertIntoTwoDigits(productData?.returnData?.returnCount)
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Approved Returns
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.returnData?.approveReturn
                          ? ConvertIntoTwoDigits(
                            productData?.returnData?.approveReturn
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Pending Returns
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.returnData?.pendingReturn
                          ? ConvertIntoTwoDigits(
                            productData?.returnData?.pendingReturn
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Rejected Returns
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.returnData?.rejectReturn
                          ? ConvertIntoTwoDigits(
                            productData?.returnData?.rejectReturn
                            )
                          : '00'}
                      </strong>
                    </Grid>
                  </Grid>
                </StyledCard>
              </Grid>

              <Grid item xs={12} md={4} lg={4} sm={6}>
                <StyledCard
                  sx={{ backgroundColor: '#f5f5f5', minHeight: '148px' }}
                >
                  <Grid container>
                    <Grid item lg={10}>
                      Total Custom Products Orders
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={1}>
                      <strong>
                        {productData?.customData?.customCount
                          ? ConvertIntoTwoDigits(
                            productData?.customData?.customCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={10}>
                      Closed Custom Products Orders
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={1}>
                      <strong>
                        {productData?.customData?.closeCustom
                          ? ConvertIntoTwoDigits(
                            productData?.customData?.closeCustom
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={10}>
                      Open Custom Products Orders
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={1}>
                      <strong>
                        {productData?.customData?.openCustom
                          ? ConvertIntoTwoDigits(
                            productData?.customData?.openCustom
                            )
                          : '00'}
                      </strong>
                    </Grid>
                  </Grid>
                </StyledCard>
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <StyledCard
                  sx={{ backgroundColor: '#f5f5f5', minHeight: '148px' }}
                >
                  <Grid container>
                    <Grid item lg={10}>
                      Total Custom Requests
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={1}>
                      <strong>
                        {productData?.customRequestCount
                          ? ConvertIntoTwoDigits(
                              productData?.customRequestCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={10}>
                      Accepted Requests
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={1}>
                      <strong>
                        {productData?.acceptedCustomeRequest?.[0]
                          ?.acceptedCustomeRequestCount
                          ? ConvertIntoTwoDigits(
                              productData?.acceptedCustomeRequest?.[0]
                                ?.acceptedCustomeRequestCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={10}>
                      Requests Pending for Order
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={1}>
                      <strong>
                        {productData?.requestsPendingForOrder?.[0]
                          ?.requestsPendingForOrderCount
                          ? ConvertIntoTwoDigits(
                              productData?.requestsPendingForOrder?.[0]
                                ?.requestsPendingForOrderCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                  </Grid>
                </StyledCard>
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <StyledCard
                  sx={{ backgroundColor: '#f5f5f5', minHeight: '148px' }}
                >
                  <Grid container>
                    <Grid item lg={9}>
                      Total Pending Payments
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={2}>
                      <strong>-</strong>
                    </Grid>
                    <Grid item lg={9}>
                      Pending Payments to Seller
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={2}>
                      <strong>-</strong>
                    </Grid>
                    <Grid item lg={9}>
                      Pending Refunds
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={2}>
                      <strong>-</strong>
                    </Grid>
                  </Grid>
                </StyledCard>
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <StyledCard
                  sx={{ backgroundColor: '#f5f5f5', minHeight: '148px' }}
                >
                  <Typography sx={{ mt: 2 }} align="center" variant="p">
                    Total Income
                  </Typography>
                  <Typography sx={{ mb: 2 }} align="center" variant="p">
                    <strong>-</strong>
                  </Typography>
                </StyledCard>
              </Grid>
            </Grid>
          </Container>
          <br />
          <br />
          </>
      )}
    </>
  );
}
        