import React, { useEffect } from "react";
import Routes from "./routes/Routes";
import "./assets/css/style.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

import { Suspense } from "react";
import loading from '../src/assets/images/gif/loading.gif'


function App() {
  {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
    console.warn = () => {}
  }
  return (
   <Suspense fallback={<loading />}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    </Suspense>
  );
}

export default App;
