import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorMsg } from "../../../../component/customComponents/errorMsg/ErrorMsg";
import { Controller, useForm } from "react-hook-form";
import { MuiOtpInput } from "mui-one-time-password-input";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  Button,
  Typography,
  Chip,
  Grid,
  OutlinedInput,
  Link,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Header from "../../../../component/user/defaultLayout/Header";
import Footer from "../../../../component/user/defaultLayout/Footer";
import Png from "../../../../assets/png";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import UserSagaActions from "../../../../redux/Usersaga/action";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { styled } from "@mui/material/styles";
import HighlightOffTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import { PrimaryButton } from "../../../../component/user/button/Button";
import { Theme, useTheme } from "@mui/material/styles";
import Mui from "../../../../component/customComponents/mui/Mui";
import { CircularProgress } from "@mui/material";
import UserForgotPassword from "../../auth/forgotPassword/UserForgotPassword";

const { VerifyOtpEmail } = UserSagaActions;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function OtpVerify({
  Closemodel,
  Openmodel,
  data,
  OpenLoginModel,
  handleCloseSignup,
  handleOpenSignup,
  setBackBtnType,
  backBtnType,
  userResendOtpEmail,
  setOpenOtpForm
}) {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const history = useHistory();
  const [resendOtpDisabled, setResendOtpDisabled] = useState(false);
  const [resendOtpType, setResendOtpType] = useState(false);

  const { updateModalSaga,stateEmpty } = UserSagaActions;
  const loding = useSelector((state) => {
    return state.Usersaga.loading;
  });

  const getOtp = useSelector((state) => {
    return state.Usersaga.SendOtpEmail;
  });

  const ForgotPasswordData = useSelector((state) => {
    return state.Usersaga.ForgotPasswordData;
  });

  const { forgotSuccess, verifyOtpSuccess } = useSelector((state) => {
    return state.Usersaga;
  });



  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [otpError, setOtpError] = useState("");
  const [emailError, setEmailError] = useState("");

  const errorHandel = (e) => {
    if (otp !== "") {
      setOtpError("");
    }
    else if (otp.length != 4) {
      setOtpError("Please enter valid OTP");
    }
    else {
      setOtpError("Please enter OTP");
    }
    // if (email !== "") {
    //   setEmailError("");
    // } else {
    //   setEmailError("Please enter email");
    // }
  };
  // const errorHandelEmail = (e) => {
  //   if (email !== "") {
  //     setEmailError("");
  //   } else {
  //     setEmailError("Please enter email");
  //   }
  // };

  const errorHandelEmail = (e) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email === "") {
      setEmailError("Please enter email");
      return false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter valid email");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const resendUserOtp = (e) => {
    setMinutes(1);
    setSeconds(59);
    // errorHandling(e);
    // errorHandelEmail();
    if (userResendOtpEmail?.trim() != "") {
      let urlencoded = new URLSearchParams();
      urlencoded.append("email", userResendOtpEmail?.trim());
      dispatch(ResendUserOtpSaga(urlencoded, navigate));
    }
  };

  console.log(data,'data');
  
  const handelsubmit = () => {
    errorHandel();
    if (otp !== "" && otp?.length == 4) {
      if (data == 1) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("otp", otp);
        urlencoded.append("id", ForgotPasswordData._id);
        urlencoded.append("mailType", "1");
        dispatch(VerifyOtpEmail(urlencoded, navigate, "forgotPassword"));

        // Closemodel(false);
        // Closemodel(false);
        // Openmodel(true);
        // history.push("/user/forgotpassword");
      } else {
        let urlencoded = new URLSearchParams();
        urlencoded.append("otp_via_email", otp);
        urlencoded.append("id", getOtp?.data?._id);
        dispatch(VerifyOtpEmail(urlencoded, navigate));
        // Closemodel(false);
        // history.push("/user/membershipform");
        // history.push("/user/forgotpassword");
      }
    }
  };
  useEffect(() => {
    if (verifyOtpSuccess==true) {
      setBackBtnType('password');
    }
  }, [verifyOtpSuccess])


  
  // OTP change function
  const handleChange = (newValue) => {
    setOtp(newValue);
    if (!isNaN(newValue) && newValue.length === 4) {
      setOtpError();
    }
  };
  const { loginSaga, ForgotPasswordSaga, ResendUserOtpSaga } = UserSagaActions;

  // useEffect(() => {
  //   if (openData == true) {
  //     setBackBtnType('otp_verify');
  //   }
  // }, [openData]);
  const forgotPassword = (e) => {
    setMinutes(1);
    setSeconds(59);
    // errorHandling(e);
    errorHandelEmail();

    if (errorHandelEmail()) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email?.trim() != "" && emailRegex.test(email) && email !== "") {
        let urlencoded = new URLSearchParams();
        urlencoded.append("email", email?.trim());
        dispatch(ForgotPasswordSaga(urlencoded, navigate));



        setResendOtpType(true);
        setResendOtpDisabled(true);
        // setForgotData(1);
        // Closemodel(false);
        // OpenOtpmodel(true);
        // setBackBtnType(true);
      }
    }
  };

  console.log(backBtnType,'niru');
  
  useEffect(() => {
    if (forgotSuccess == true) {
      setBackBtnType('otp_verify');
    }
  }, [forgotSuccess])
  // otp timer
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <ValidatorForm>
      <div>
        <Box className="membership-sec">
          <Box sx={style} className="membership-sec-modal">
            <Box className="login-close-icon">
              <CloseIcon
                onClick={() => {
                  Closemodel(false);
                  dispatch(updateModalSaga(navigate));
                  dispatch(stateEmpty(navigate));
                }}
              />
            </Box>
            {/* forgot */}
            {backBtnType == 'forgot' && (
              <>
                {/* email  */}
                <Mui.Box>
                  <img
                    src={Png.footerlogo}
                    className="login-zaverica-logo"
                    alt="Logo"
                  ></img>
                </Mui.Box>
                {/* <h2 style={{ textAlign: "center" }}>Forgot Password</h2> */}
                <Box className="modal-content">
                    <Typography
                      className="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Forgot Password
                    </Typography>
                  </Box>
                <Box className="input-box membership-input-box" noValidate>
                  <FormHelperText className="input-lable">Email</FormHelperText>
                  <TextValidator
                    fullWidth
                    id="fullWidth"
                    value={email}
                    placeholder="Enter email"
                    className="form-control border-none"
                    name="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  // errorMessages={["Please enter valid email"]}
                  // validators={["required", "isEmail"]}
                  />
                  {/* <Box className="sign-up-error-message">{emailError}</Box> */}
                  {emailError && (
                    <FormHelperText error>{emailError}</FormHelperText>
                  )}
                </Box>
                <Box className="modal-btn-box">
                  <PrimaryButton
                    // sx={{ }}
                    btnclass="btn modal-btn modal-btn-submit membership-submit-btn"
                    sx={{ maxWidth: "190px", marginTop: "20px !important" }}
                    onClick={() => {
                      forgotPassword();
                      // setBackBtnType(true)
                    }}
                    buttonvalue="Send OTP"
                  />
                </Box>
              </>
            )}

            {backBtnType == 'otp_verify' && (
              <>
                <Box
                  className="otp-flex-input-content"
                // sx={{ marginBottom: "24px" }}
                >
                  <Mui.Box>
                    <img
                      src={Png.footerlogo}
                      className="login-zaverica-logo"
                      alt="Logo"
                    ></img>
                  </Mui.Box>
                  <Box className="modal-content">
                    <Typography
                      className="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      OTP Verify
                    </Typography>
                  </Box>
                  {/* <h2 style={{ textAlign: "center" }}>OTP Verify</h2> */}
                  <Box className="comman-details-auth-user input-box membership-input-box">
                    <FormHelperText className="input-lable">
                      OTP
                      {/* {/ <span style={{ color: "red", fontSize: "15px" }}> </span> /} */}
                    </FormHelperText>
                    <Box className="otp-flex-main-user otp-custom-details">
                      <MuiOtpInput
                        // sx={{ marginBottom: "24px !important" }}
                        className="otp-input"
                        // length={4}
                        // value={otp}
                        // onChange={handleChange}
                        // onChange={(e) => {
                        //   setOtp(e.target.value);
                        // }}
                        length={4}
                        value={otp}
                        onChange={handleChange}
                        name="otp"
                        inputProps={[{ inputMode: "numeric" }]}
                        validateChar={(val) => !isNaN(val)}
                      />
                      {otpError && (
                        <FormHelperText error>{otpError}</FormHelperText>
                      )}
                    </Box>

                    <Box className="modal-btn-box">
                      <PrimaryButton
                        // sx={{ }}
                        btnclass="btn modal-btn modal-btn-submit membership-submit-btn"
                        sx={{ maxWidth: "190px", marginTop: "10px !important" }}
                        onClick={() => {
                          handelsubmit();
                          // setBackBtnType(true)
                        }}
                        buttonvalue="Submit"
                      />
                    </Box>
                    {/* <Box className="resend-pass-right">
                <Link className="redirect-resend">Resend OTP</Link>
                <Button
                  className="redirect-resend"
                  onClick={handleResend}
                >
                  Resend OTP
                </Button>
              </Box> */}

                    {/* <Box className="orange-btn login-btn login-btn-main">
                <Button
                  type="submit"
                  data-testid="button"
                  variant="contained"
                  // onClick={() => handleFormSubmit()}
                  // disabled={isDisabled}
                >
                  Submit
                </Button>
              </Box> */}
                    {/* <Index.Box className="btn-list-login-content">
                          <Index.Box className="login-btn-list">
                            <PageIndex.BlueButton
                              btnLabel="Submit"
                              className="blue-btn-content"
                              onClick={() => handleFormSubmit()}
                            />
                          </Index.Box>
                        </Index.Box> */}
                  </Box>
                </Box>
                <Box className="countdown-text reset--otp-main">
                  {seconds > 0 || minutes > 0 ? (
                    <p>
                      Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    ""
                  )}
                  {!(seconds > 0 || minutes > 0) && (
                    <Box className="resend-button resend-pass-right">
                      <Button
                        type="button"
                        btnLabel="Resend Otp"
                        btnclass="btn modal-btn modal-btn-submit membership-submit-btn"
                        sx={{
                          maxWidth: "190px",
                          marginTop: "10px !important",
                          color: "#c29958",
                          fontSize: "17px",
                          textTransform: "none",
                        }}
                        onClick={() => {
                          if (resendOtpType) {
                            forgotPassword();
                          } else {
                            resendUserOtp();
                          }
                        }}
                        // onClick={() => {
                        //   handelsubmit();
                        // }}
                        className="redirect-resend"
                      >
                        Resend Otp
                      </Button>
                    </Box>
                  )}
                </Box>
              </>
            )}
            {backBtnType == 'password' && <UserForgotPassword
              handleOpenSignup={handleOpenSignup} OpenLoginModel={OpenLoginModel}
              Closemodel={Closemodel} backBtnType={backBtnType} />}

            {backBtnType ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    cursor: "pointer",
                    marginTop: "7px",
                    color: "rgb(194, 153, 88)",
                    fontSize: "14px",
                  }}
                >
                  <Typography
                    variant="body1"
                    // component="p"
                    className="forgot_password"
                    onClick={() => {
                      OpenLoginModel(true);
                      Closemodel(false);
                      dispatch(updateModalSaga(navigate));
                      dispatch(stateEmpty(navigate));
                      // handleCloseSignup();
                    }}
                  >
                    Back to Sign In?
                  </Typography>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  cursor: "pointer",
                  marginTop: "7px",
                  color: "rgb(194, 153, 88)",
                  fontSize: "14px",
                }}
              >
                <Typography
                  variant="body1"
                  // component="p"
                  className="forgot_password"
                  onClick={() => {
                    handleOpenSignup(true);
                    Closemodel(false);
                    dispatch(updateModalSaga(navigate));
                    // handleCloseSignup();
                  }}
                >
                  Back to Sign Up?
                </Typography>
              </Box>
            )}
          </Box>

        </Box>
      </div>
    </ValidatorForm>
  );
}
