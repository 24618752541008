import React, { useEffect, useState } from "react";
import png from "../../../../assets/png";
import PropTypes from "prop-types";
import UserIndex from "../../UserIndex";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserSagaActions from "../../../../redux/Usersaga/action";
import { price } from "../../../../component/customComponents/commonFunctions/CommonFunctions";

const { ReceivedOrderSaga, GetOrderListSaga } = UserSagaActions;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <UserIndex.Box sx={{ p: 3 }}>
          <UserIndex.Typography>{children}</UserIndex.Typography>
        </UserIndex.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyOrder = () => {
  const [value, setValue] = useState(0);
  const [tab, setTab] = useState(0);
  const [received, setReceived] = useState([]);
  const [orderReceived, setOrderReceived] = useState([]);
  const [allOrder, setAllOrder] = useState([]);
  const [orders, setOrders] = useState([]);
  const dispatch = UserIndex.useDispatch();
  const history = UserIndex.useHistory();
  const navigate = UserIndex.useHistory();
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const Token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });
  const ReceivedOrderdata = UserIndex.useSelector((state) => {
    return state.Usersaga.ReceivedOrderdata;
  });

  const GetOrderListdata = UserIndex.useSelector((state) => {
    return state.Usersaga.GetOrderListdata;
  });

  const getReceivedOrder = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(ReceivedOrderSaga(urlencoded, navigate, Token));
  };

  const getGetOrderList = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(GetOrderListSaga(urlencoded, navigate, Token));
  };

  useEffect(() => {
    setAllOrder(GetOrderListdata);
    setOrders(GetOrderListdata);
  }, [GetOrderListdata]);

  useEffect(() => {
    setReceived(ReceivedOrderdata);
    setOrderReceived(ReceivedOrderdata);
  }, [ReceivedOrderdata]);

  useEffect(() => {
    getReceivedOrder();
  }, [tab]);

  useEffect(() => {
    getGetOrderList();
  }, [tab]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const [age, setAge] = useState("");


  const myOrderhandleChange = (event) => {
    let { value } = event.target;
    if (tab == 0) {
      if (value === 10) {
        setAllOrder(
          orders.filter(
            (e) =>
              e.orderItemStatus === "1" ||
              e.orderItemStatus === "0" ||
              e.orderItemStatus === "2"
          )
        );
      } else if (value === 20) {
        setAllOrder(orders.filter((e) => e.orderItemStatus === "Close"));
      } else {
        setAllOrder(orders);
      }
    } else if (tab == 1) {
      if (value === 10) {
        setReceived(
          orderReceived.filter(
            (e) =>
              e.orderItemStatus === "1" ||
              e.orderItemStatus === "0" ||
              e.orderItemStatus === "2"
          )
        );
      } else if (value === 20) {
        setReceived(orderReceived.filter((e) => e.orderItemStatus === "Close"));
      } else {
        setReceived(orderReceived);
      }
    }

    setAge(value);
  };


  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="body-bg">
        <UserIndex.Box className="myorder-sec">
          <UserIndex.Box className="container">
            <UserIndex.Box className="row">
              <UserIndex.Box className="addinfo-text-box pg-main-title-box">
                <UserIndex.Typography
                  variant="h2"
                  className="addinfo-text pg-main-title"
                >
                  My Orders
                </UserIndex.Typography>
              </UserIndex.Box>
              <UserIndex.Box className="mo-tabs-box">
                <UserIndex.Box className="mo-tabs-main">
                  <UserIndex.Tabs
                    value={value}
                    onChange={(_, d) => {
                      handleChange(d);
                    }}
                    aria-label="basic tabs example"
                    className="mo-tabs"
                  >
                    <UserIndex.Tab
                      label="Orders Placed"
                      onClick={() => setTab(0)}
                      className="mo-tabs-lable"
                    />
                    <UserIndex.Tab
                      label="Orders Received"
                      onClick={() => {
                        setTab(1);
                      }}
                      className="mo-tabs-lable"
                    />
                  </UserIndex.Tabs>
                </UserIndex.Box>
                <UserIndex.Box className="mo-tabs-content">
                  <UserIndex.Box>
                    <UserIndex.Box className="input-box">
                      <UserIndex.FormControl className="membership-formcontrol mo-form-control-drop">
                        <UserIndex.Select
                          className="membershop-select custom-dropdown-arrow"
                          value={age}
                          onChange={myOrderhandleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <UserIndex.MenuItem
                            value=""
                            className="membershipitem"
                          >
                            All
                          </UserIndex.MenuItem>
                          <UserIndex.MenuItem value={10}>
                            Open
                          </UserIndex.MenuItem>
                          <UserIndex.MenuItem value={20}>
                            Close
                          </UserIndex.MenuItem>
                        </UserIndex.Select>
                        {/* <span>
                          <img
                            src={png.blackdown}
                            className="black-down-img"
                          ></img>
                        </span> */}
                      </UserIndex.FormControl>
                    </UserIndex.Box>
                    <UserIndex.Box className="mo-table-main">
                      <UserIndex.TableContainer
                        component={UserIndex.Paper}
                        className="mo-table-container"
                      >
                        <UserIndex.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="mo-table"
                        >
                          <UserIndex.TableHead className="mo-tablehead">
                            <UserIndex.TableRow>
                              <UserIndex.TableCell className="mo-th">
                                Order Date
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="mo-th">
                                Order Id
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="mo-th">
                                Order Price
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="mo-th">
                                Status
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="mo-th"></UserIndex.TableCell>
                            </UserIndex.TableRow>
                          </UserIndex.TableHead>
                          <UserIndex.TableBody className="mo-table-body">
                            {tab == 0 ? (
                              <>
                                {allOrder?.length
                                  ? allOrder?.map((data, index) => {
                                      return (
                                        <UserIndex.TableRow
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          <UserIndex.TableCell
                                            component="th"
                                            scope="row"
                                            className="mo-td"
                                          >
                                            {new Date(
                                              data?.createdAt
                                            ).toLocaleDateString()}
                                          </UserIndex.TableCell>
                                          <UserIndex.TableCell className="mo-td">
                                            {data?.order_id}
                                          </UserIndex.TableCell>
                                          <UserIndex.TableCell className="mo-td">
                                            {price(data?.totalAmount)}
                                          </UserIndex.TableCell>
                                          <UserIndex.TableCell className="mo-td success-text">
                                            {data?.orderItemStatus == "0" ? (
                                              <UserIndex.Box
                                                sx={{ color: "#198F0B" }}
                                              >
                                                Open
                                              </UserIndex.Box>
                                            ) : data?.orderItemStatus == "1" ? (
                                              <UserIndex.Box
                                                sx={{ color: "#198F0B" }}
                                              >
                                                Open
                                              </UserIndex.Box>
                                            ) : data?.orderItemStatus == "2" ? (
                                              <UserIndex.Box
                                                sx={{ color: "#198F0B" }}
                                              >
                                                Open
                                              </UserIndex.Box>
                                            ) : data?.orderItemStatus ==
                                              "Close" ? (
                                              <UserIndex.Box
                                                sx={{ color: "#FF0303" }}
                                              >
                                                Close
                                              </UserIndex.Box>
                                            ) : (
                                              ""
                                            )}
                                          </UserIndex.TableCell>
                                          <UserIndex.TableCell className="mo-td">
                                            <UserIndex.Box className="primary-btn-box myorder-view-btn-box">
                                              <UserIndex.PrimaryButton
                                                btnclass="primary-btn myorder-view-btn "
                                                buttonvalue="View"
                                                onClick={() =>
                                                  history.push(
                                                    `/user/orderplaceviewpage/${data?._id}`
                                                  )
                                                }
                                              />
                                            </UserIndex.Box>
                                          </UserIndex.TableCell>
                                        </UserIndex.TableRow>
                                      );
                                    })
                                  :  
                                   <UserIndex.TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th":
                                      {
                                        border: 0,
                                      },
                                  }}
                                >
                                  <UserIndex.TableCell
                                  colSpan={5}
                                    component="th"
                                    align="center"
                                    // scope="row"
                                    // className="mo-td"
                                  >No Record Found
                                  </UserIndex.TableCell> </UserIndex.TableRow>
                                  }
                              </>
                            ) : (
                              <>
                                <UserIndex.Box className="productr-orderproduct-data">
                                  Products Orders
                                </UserIndex.Box>
                                {received[0]?.length ? (
                                  received[0]?.map((data, index) => {
                                    return (
                                      <>
                                        <UserIndex.TableRow
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          <UserIndex.TableCell
                                            component="th"
                                            scope="row"
                                            className="mo-td"
                                          >
                                            {/* 28-04-2022 */}
                                            {new Date(
                                              data?.createdAt
                                            ).toLocaleDateString()}
                                          </UserIndex.TableCell>
                                          <UserIndex.TableCell className="mo-td">
                                            {data?.order_id}
                                          </UserIndex.TableCell>
                                          <UserIndex.TableCell className="mo-td">
                                            {price(data?.totalAmount)}
                                          </UserIndex.TableCell>
                                          <UserIndex.TableCell className="mo-td success-text">
                                            {/* {data.orderItemStatus == 0 && 1 && 2
                                              ? "Open"
                                              : "Close"} */}
                                            {data?.orderItemStatus == "0" ? (
                                              <UserIndex.Box
                                                sx={{ color: "#198F0B" }}
                                              >
                                                Open
                                              </UserIndex.Box>
                                            ) : data?.orderItemStatus == "1" ? (
                                              <UserIndex.Box
                                                sx={{ color: "#198F0B" }}
                                              >
                                                Open
                                              </UserIndex.Box>
                                            ) : data?.orderItemStatus == "2" ? (
                                              <UserIndex.Box
                                                sx={{ color: "#198F0B" }}
                                              >
                                                Open
                                              </UserIndex.Box>
                                            ) : data?.orderItemStatus ==
                                              "Close" ? (
                                              <UserIndex.Box
                                                sx={{ color: "#FF0303" }}
                                              >
                                                Close
                                              </UserIndex.Box>
                                            ) : (
                                              ""
                                            )}
                                          </UserIndex.TableCell>
                                          <UserIndex.TableCell className="mo-td">
                                            <UserIndex.Box className="primary-btn-box myorder-view-btn-box">
                                              <UserIndex.PrimaryButton
                                                btnclass="primary-btn myorder-view-btn "
                                                buttonvalue="View"
                                                onClick={() =>
                                                  history.push(
                                                    `/user/ordersreceivedviewpage/${data?._id}`
                                                  )
                                                }
                                              />
                                            </UserIndex.Box>
                                          </UserIndex.TableCell>
                                        </UserIndex.TableRow>
                                      </>
                                    );
                                  })
                                ) : (
                                  <UserIndex.TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th":
                                      {
                                        border: 0,
                                      },
                                  }}
                                >
                                  <UserIndex.TableCell
                                  colSpan={5}
                                    component="th"
                                    align="center"
                                    // scope="row"
                                    // className="mo-td"
                                  >No Record Found
                                  </UserIndex.TableCell> </UserIndex.TableRow>
                                )}
                                <UserIndex.Box className="productr-orderproduct-data">
                                  Custom Products Orders
                                </UserIndex.Box>
                                {received[1]?.length ? (
                                  received[1]?.map((data, index) => {
                                    return (
                                      <>
                                        <UserIndex.TableRow
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          <UserIndex.TableCell
                                            component="th"
                                            scope="row"
                                            className="mo-td"
                                          >
                                            {/* 28-04-2022 */}
                                            {new Date(
                                              data?.createdAt
                                            ).toLocaleDateString()}
                                          </UserIndex.TableCell>
                                          <UserIndex.TableCell className="mo-td">
                                            {data?.order_id}
                                          </UserIndex.TableCell>
                                          <UserIndex.TableCell className="mo-td">
                                            {price(data?.totalAmount)}
                                          </UserIndex.TableCell>
                                          <UserIndex.TableCell className="mo-td success-text">
                                            {data?.orderItemStatus == "0" ? (
                                              <UserIndex.Box
                                                sx={{ color: "#198F0B" }}
                                              >
                                                Open
                                              </UserIndex.Box>
                                            ) : data?.orderItemStatus == "1" ? (
                                              <UserIndex.Box
                                                sx={{ color: "#198F0B" }}
                                              >
                                                Open
                                              </UserIndex.Box>
                                            ) : data?.orderItemStatus == "2" ? (
                                              <UserIndex.Box
                                                sx={{ color: "#198F0B" }}
                                              >
                                                Open
                                              </UserIndex.Box>
                                            ) : data?.orderItemStatus ==
                                              "Close" ? (
                                              <UserIndex.Box
                                                sx={{ color: "#FF0303" }}
                                              >
                                                Close
                                              </UserIndex.Box>
                                            ) : (
                                              ""
                                            )}
                                          </UserIndex.TableCell>
                                          <UserIndex.TableCell className="mo-td">
                                            <UserIndex.Box className="primary-btn-box myorder-view-btn-box">
                                              <UserIndex.PrimaryButton
                                                btnclass="primary-btn myorder-view-btn "
                                                buttonvalue="View"
                                                onClick={() =>
                                                  history.push(
                                                    `/user/ordersreceivedviewpage/${data?._id}`
                                                  )
                                                }
                                              />
                                            </UserIndex.Box>
                                          </UserIndex.TableCell>
                                        </UserIndex.TableRow>
                                      </>
                                    );
                                  })
                                ) : (
                                  <UserIndex.TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th":
                                      {
                                        border: 0,
                                      },
                                  }}
                                >
                                  <UserIndex.TableCell
                                  colSpan={5}
                                    component="th"
                                    align="center"
                                    // scope="row"
                                    // className="mo-td"
                                  >No Record Found
                                  </UserIndex.TableCell> </UserIndex.TableRow>
                                )}
                              </>
                            )}
                          </UserIndex.TableBody>
                        </UserIndex.Table>
                      </UserIndex.TableContainer>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Footer />
    </div>
  );
};

export default MyOrder;
