import React, { useEffect, useState } from "react";
import png from "../../../../assets/png";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormHelperText,
  CircularProgress,
  OutlinedInput,
} from "@mui/material";
import { styled } from "@mui/system";
import { Api } from "../../../../config/api";
import dataService from "../../../../config/dataService";
import { Link, useHistory } from "react-router-dom";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import UserSagaActions from "../../../../redux/Usersaga/action";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import UserIndex from "../../UserIndex";

const { loginSaga, ForgotPasswordSaga } = UserSagaActions;
const StyledProgress = styled(CircularProgress)(() => ({
  position: "absolute",
  top: "6px",
  left: "25px",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 631,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Login = ({
  Closemodel,
  handleCloseLogin,
  Openmodel,
  OpenOtpmodel,
  setForgotData,
  backBtnType,
  setBackBtnType,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const navigate = useHistory();
  const loding = useSelector((state) => {
    return state.Usersaga.loading;
  });
  const ForgotPasswordData = useSelector((state) => {
    return state.Usersaga.ForgotPasswordData;
  });

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleChangeUser = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  // const errorHandling = (e) => {
  //   if (user.email == "") {
  //     setEmailError("Please enter email");
  //   } else {
  //     setEmailError("");
  //   }
  // };

  const errorHandling = (e) => {
    // Regular expression for email validation
    const emailRegex = /^(?!.*\.\.)(?!.*--)(?!\.)[a-zA-Z0-9._-]+(?<![-.])@[a-zA-Z0-9-]+(?<!-)\.[a-zA-Z]{2,}$/

    if (user.email === "") {
      setEmailError("Please enter email");
    } else if (!emailRegex.test(user.email)) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }
  };
  // const errorHandling = () => {
  //   if (!user.email.trim()) {
  //     setEmailError("Please enter email");
  //   } else {
  //     setEmailError("");
  //   }
  // };

  // const errorHandling = (email) => {
  //   if (!user.email.trim()) {
  //     setEmailError("Please enter email");
  //   } else if (!/\S+@\S+\.\S+/.test(email)) {
  //     setEmailError("Please enter a valid email");
  //   } else {
  //     setEmailError("");
  //   }
  // };
  // const errorHandling = (email) => {
  //   if (!user.email.trim()) {
  //     setEmailError("Please enter email");
  //   } else if (
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
  //       email
  //     )
  //   ) {
  //     setEmailError("Please enter a valid email");
  //   } else {
  //     setEmailError("");
  //   }
  // };

  const errorHandlingPassword = (e) => {
    if (user.password == "") {
      setPasswordError("Please enter password");
    } else {
      setPasswordError("");
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginSubmit = async (e) => {
    errorHandling();
    errorHandlingPassword();
    if (user.email?.trim() != "" && user.password?.trim() != "") {
      let urlencoded = new URLSearchParams();
      urlencoded.append("email", user.email?.trim());
      urlencoded.append("password", user.password?.trim());
      dispatch(loginSaga(urlencoded, navigate));
    } else {
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLoginSubmit();
      Closemodel(false);
    }
  };

  const forgotPassword = (e) => {
    errorHandling(e);
    // if (user.email?.trim() != "") {
    //   let urlencoded = new URLSearchParams();
    // urlencoded.append("email", user.email?.trim());
    // dispatch(ForgotPasswordSaga(urlencoded, navigate));
    setForgotData(1);
    Closemodel(false);
    OpenOtpmodel(true);
    setBackBtnType("forgot");
    // }
  };

  return (
    <div>
      <ValidatorForm>
        <Box sx={style}>
          <Box className="login-close-icon">
            <CloseIcon
              onClick={() => {
                handleCloseLogin();
              }}
            />
          </Box>
          <Box className="modal-logo-box">
            <img src={png.footerlogo} className="modal-logo-img"></img>
          </Box>
          <Box className="modal-content">
            <Typography
              className="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Sign In
            </Typography>
            {/* <h2 style={{ textAlign: "center" }}>Sign In</h2> */}
            <Box className="input-box" noValidate>
              <FormHelperText className="input-lable">
                Email
                <span style={{ color: "red", fontSize: "15px" }}> </span>
              </FormHelperText>
              <TextValidator
                fullWidth
                id="fullWidth"
                // className="form-control border-none"
                value={user.email}
                placeholder="Enter email"
                // className="light-blank-placeholder"
                className="form-control border-none"
                name="email"
                onChange={(e) => {
                  handleChangeUser(e);
                }}
              // validators={["required", "isEmail"]}
              // errorMessages={["Please enter valid email"]}
              />
              <UserIndex.FormHelperText className="error-input-lable">
                {emailError}
              </UserIndex.FormHelperText>
            </Box>
            <Box className="input-box">
              <FormHelperText className="input-lable">
                Password
                <span style={{ color: "red", fontSize: "15px" }}> </span>
              </FormHelperText>
              <OutlinedInput
                type={values.showPassword ? "text" : "password"}
                fullWidth
                id="fullWidth"
                // className="border-none login-field-password light-blank-placeholder"
                className="form-control border-none border-none login-field-password light-blank-placeholder"
                value={user.password}
                placeholder="Enter password"
                // className="light-blank-placeholder"
                name="password"
                onChange={(e) => {
                  handleChangeUser(e);
                }}
                onKeyDown={handleKeyDown}
                inputProps={{ maxLength: 20 }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <UserIndex.FormHelperText className="error-input-lable">
                {passwordError}
              </UserIndex.FormHelperText>
            </Box>
            <Box className="forgot-password-box">
              <Typography className="forgot-password-text">
                <Link
                  style={{ color: "#c29958" }}
                  onClick={forgotPassword}
                // to="/user/forgotpassword"
                >
                  Forgot Password?
                </Link>
              </Typography>
            </Box>
            <Box className="modal-btn-box">
              <Button
                className="btn modal-btn"
                onClick={() => {
                  handleLoginSubmit();
                  // Closemodel(false);
                }}
              >
                Sign In
              </Button>
            </Box>
            <Typography
              className="modal-footer-para"
              variant="h6"
              component="h2"
            >
              Don't have an account?{" "}
              <span
                className="modal-span"
                onClick={() => {
                  Closemodel(false);
                  Openmodel(true);
                  setForgotData('')
                }}
              >
                Sign Up
              </span>
            </Typography>
          </Box>
        </Box>
        {/* <ToastContainer/> */}
      </ValidatorForm>
    </div>
  );
};

export default Login;
