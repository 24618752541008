import React, { useEffect, useState } from "react";
import png from "../../../../assets/png";
import {
  Box,
  Typography,
  FormHelperText,
  CircularProgress,
  OutlinedInput,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import UserSagaActions from "../../../../redux/Usersaga/action";
import { styled } from "@mui/system";
import { useHistory } from "react-router-dom";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { ToastContainer, toast } from "react-toastify";
import { PrimaryButton } from "../../../../component/user/button/Button";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SignUpOtpModel from "../../pages/membershipform/SignUpOtpModel";

const { SignInSaga, sendOtpEmail } = UserSagaActions;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 631,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const StyledProgress = styled(CircularProgress)(() => ({
  position: "absolute",
  top: "6px",
  left: "25px",
}));

// const CustomToast = () => {
//   toast("Please enter valid data", {
//     toastId: "success1",
//   });
// };

const SignUp = ({
  Closemodel,
  Openmodel,
  OpenOtpmodel,
  // CloseOtpmodel,
  setOpenOtpForm,
  openOtpForm,
  openOtpFormState,
  setBackBtnType,
  userResendOtpEmail,
  setUserResendOtpEmail,
  setForgotData
}) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  // const [openOtpForm, setOpenOtpForm] = useState(false);
  // const handleOpenOtpForm = () => setOpenOtpForm(true);
  const handleCloseOtpForm = () => {
    setOpenOtpForm(false);
    // dispatch(updateModalSaga(navigate));
  };

  const loding = useSelector((state) => {
    return state.Usersaga.loading;
  });

  // useEffect(() => {
  //   if (open) {
  //     // setOpenModal(open)
  //     OpenOtpmodel(true);
  //     // Closemodel(false);
  //   }
  // }, [open]);

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [validation, setValidation] = useState(true);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [otpModelData, setOtpModelData] = useState(false);
  const openData = useSelector((state) => {
    return state.Usersaga.open;
  });
console.log(openData,'openData');

  useEffect(() => {
    if (openData) {
      Closemodel(false);
      // alert("open check");
      // OpenOtpmodel(true);
      setOpenOtpForm(true);
      setOtpModelData(true);
      setBackBtnType('otp_verify');
    } else {
      setOpenOtpForm(false);
    }
  }, [openData]);
  // useEffect(() => {
  //   if (openData == true) {
  //     setOtpModelData(true);
  //   }
  // }, []);

  const nameerrorHandling = () => {
    const regex = new RegExp(/^[A-Za-z\s]+$/);
    if (!regex.test(name)) {
      setNameError("Please enter full name");
      return false;
    } else if (name === "") {
      setNameError("Please enter name");
      return false;
    } else {
      setNameError("");
      return true;
    }
  };

  const emailerrorHandling = () => {
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex=/^(?!.*\.\.)(?!.*--)(?!\.)[a-zA-Z0-9._-]+(?<![-.])@[a-zA-Z0-9-]+(?<!-)\.[a-zA-Z]{2,}$/

    if (email === "") {
      setEmailError("Please enter email");
      return false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const validatePassword = () => {
    if (confirmPassword !== "") {
      // if (password === confirmPassword) {
      if (password != confirmPassword) {
        setConfirmPasswordError("Confirm password doesn't match with password");
        return false;
      } else {
        setConfirmPasswordError("");
        return true;
      }
    }
  };

  // useEffect(() => {
  //   validatePassword();
  // }, [password, confirmPassword]);

  const passworderrorHandling = () => {
    const digitVal = new RegExp(/(?=.*\d)/);
    const specVal = new RegExp(/(?=.*\W)/);
    const capitalVal = new RegExp(/(?=.*[A-Z])/);
    const eightVal = new RegExp(/^(?=.*\d).{8,}$/);
    if (
      password === "" ||
      !digitVal.test(password) ||
      !specVal.test(password) ||
      !capitalVal.test(password) ||
      !eightVal.test(password)
    ) {
      setPasswordError(
        "Please enter a password with minimum of 8 characters, at least 1 number, at least 1 capital letter, and at least 1 special character"
      );
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  // const passworderrorHandling = (password) => {
  //   const digitVal = new RegExp(/(?=.*\d)/);
  //   const specVal = new RegExp(/(?=.*\W)/);
  //   const capitalVal = new RegExp(/(?=.*[A-Z])/);
  //   const eightVal = new RegExp(/^(?=.*\d).{8,}$/);

  //   let isValid = true;
  //   let errorMessage = "";

  //   if (password.trim() === "") {
  //     errorMessage = "Please enter a password";
  //     isValid = false;
  //   } else if (
  //     !digitVal.test(password) ||
  //     !specVal.test(password) ||
  //     !capitalVal.test(password) ||
  //     !eightVal.test(password)
  //   ) {
  //     errorMessage = "Please enter a password with minimum of 8 characters, at least 1 number, at least 1 capital letter, and at least 1 special character";
  //     isValid = false;
  //   }

  //   setPasswordError(errorMessage);
  //   return isValid;
  // };

  const confirmPassworderrorHandling = () => {
    const digitVal = new RegExp(/(?=.*\d)/);
    const specVal = new RegExp(/(?=.*\W)/);
    const capitalVal = new RegExp(/(?=.*[A-Z])/);
    const eightVal = new RegExp(/^(?=.*\d).{8,}$/);
    if (
      confirmPassword === "" ||
      !digitVal.test(confirmPassword) ||
      !specVal.test(confirmPassword) ||
      !capitalVal.test(confirmPassword) ||
      !eightVal.test(confirmPassword)
    ) {
      setConfirmPasswordError("Please enter confirm password");
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [valuesConfirmPassword, setValuesConfirmPassword] = useState({
    showConfirmPassword: false,
  });

  const handleClickShowConfirmPassword = () => {
    setValuesConfirmPassword({
      ...valuesConfirmPassword,
      showConfirmPassword: !valuesConfirmPassword.showConfirmPassword,
    });
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setLoading(loding);
  }, [loding]);

  const sendEmail = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", email);
    urlencoded.append("owner_name", name);
    urlencoded.append("password", password);
    dispatch(sendOtpEmail(urlencoded, navigate));
  };

  const handleSignUpSubmit = async () => {
    const regex = new RegExp(/^[A-Za-z\s]+$/);
    const emailRegex = /^[^\s@]+@[^\s@]+\.(com|org|net|edu|gov|mil)$/i;
    if (
      (nameerrorHandling(),
      emailerrorHandling(),
      passworderrorHandling(),
      confirmPassworderrorHandling(),
      validatePassword())
      // validatePassword()
    ) {
      if (
        regex.test(name) &&
        name !== "" &&
        emailRegex.test(email) &&
        email !== "" &&
        password !== "" &&
        confirmPasswordError == ""
      ) {
        let urlencoded = {
          // name: name,
          owner_name: name,
          email: email,
          password: password,
          confirmPassword: confirmPassword,
        };
        dispatch(SignInSaga(urlencoded, navigate));

        if (openData == true) {
          // Closemodel(false);
          // alert("open check");
          // OpenOtpmodel(true);

          // setOpenOtpForm(true);
          // setOtpModelData(true);
          setBackBtnType('otp_verify');
        }

        setUserResendOtpEmail(email);
        // sendEmail();
        // new modal
        // handleOpenOtpForm();
      } else {
      }
    } else {
    }
  };

  return (
    <div>
      <ValidatorForm>
        <Box sx={style}>
          <Box className="login-close-icon">
            <CloseIcon
              onClick={() => {
                Closemodel(false);
              }}
            />
          </Box>
          <Box className="modal-logo-box">
            <img src={png.footerlogo} className="modal-logo-img"></img>
          </Box>
          <Box className="modal-content">
            <Typography
              className="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Sign Up
            </Typography>
             {/* <h2  style={{ textAlign: "center" }}>Sign Up</h2> */}
            <Box className="input-box">
              <FormHelperText className="input-lable">
                Full Name
                <span style={{ color: "red", fontSize: "15px" }}> </span>
              </FormHelperText>
              <TextField
                fullWidth
                id="fullWidth"
                className="form-control border-none"
                name="name"
                value={name}
                placeholder="Enter full name"
                autoComplete="off"
                type="text"
                onChange={(e) => {
                  if (e.target.value.length < 41) {
                    setName(e.target.value.trimStart().replace(/\s\s+/g, " "));
                  }
                }}
                onKeyPress={(e) => {
                  // Check if the pressed key is a special symbol or a number
                  if (/[^\w\s]/.test(e.key) || /\d/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
              <Box className="sign-up-error-message">{nameError}</Box>
            </Box>
            <Box className="input-box">
              <FormHelperText className="input-lable">
                Email
                <span style={{ color: "red", fontSize: "15px" }}> </span>
              </FormHelperText>
              <TextValidator
                fullWidth
                id="fullWidth"
                className="form-control border-none"
                name="email"
                value={email}
                placeholder="Enter email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                // validators={["required", "isEmail"]}
                // errorMessages={[
                //   "Please enter email",
                //   "Please enter valid email",
                // ]}
              />
              <Box className="sign-up-error-message">{emailError}</Box>
            </Box>
            <Box className="input-box">
              <FormHelperText className="input-lable">
                Password
                <span style={{ color: "red", fontSize: "15px" }}> </span>
              </FormHelperText>
              <OutlinedInput
                type={values.showPassword ? "text" : "password"}
                fullWidth
                id="fullWidth"
                className="form-control border-none login-field-password"
                name="password"
                value={password}
                placeholder="Enter password"
                inputProps={{ maxLength: 20 }}
                onChange={(e) => {
                  handlePassword(e);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                // validators={["required", "password"]}
                // errorMessages={["Please enter Password"]}
              />
              <Box className="sign-up-error-message">{passwordError}</Box>
            </Box>
            <Box className="input-box">
              <FormHelperText className="input-lable">
                Confirm Password
                <span style={{ color: "red", fontSize: "15px" }}> </span>
              </FormHelperText>
              <OutlinedInput
                type={
                  valuesConfirmPassword.showConfirmPassword
                    ? "text"
                    : "password"
                }
                fullWidth
                id="fullWidth"
                className="form-control border-none login-field-password"
                name="confirmPassword"
                value={confirmPassword}
                placeholder="Enter confirm password"
                inputProps={{ maxLength: 20 }}
                onChange={(e) => {
                  handleConfirmPassword(e);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {valuesConfirmPassword.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                // validators={["required"]}
                // errorMessages={["Please enter Password"]}
              />
              <Box className="sign-up-error-message">
                {confirmPasswordError}
              </Box>
            </Box>
            <Box className="modal-btn-box">
              <PrimaryButton
                btnclass="btn modal-btn"
                onClick={() => {
                  handleSignUpSubmit();
                  // sendEmail()
                }}
                buttonvalue="Next"
              />
            </Box>
            <Typography
              className="modal-footer-para"
              variant="h6"
              component="h2"
            >
              Already have an account?{" "}
              <span
                className="modal-span"
                onClick={() => {
                  Closemodel(false);
                  Openmodel(true);
                }}
              >
                Sign In
              </span>
            </Typography>
          </Box>
        </Box>
      </ValidatorForm>
      {/* <Box className="header-modals">
        <Modal
          className="login-modal"
          open={openOtpForm}
          onClose={handleCloseOtpForm}
        >
          <SignUpOtpModel Closemodel={setForgotPasswordModal} />
        </Modal>
      </Box> */}
    </div>
  );
};

export default SignUp;
